import React, { useEffect, useRef, useState } from "react";
import { motion } from 'framer-motion';

const FadeIn = (props) => {
  const { interval = 1, children } = props;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: interval }}
    >
      {children}
    </motion.div>
  );
};

const AboutPage = () => {
  const [selectedHobby, setSelectedHobby] = useState("dance");

  const hobbyDescription = {
    design: "– exploring new ways and ways to integrate experiences from other hobbies. It's a way of expressing my thoughts and ideas in a visual way",
    dance: "and moving to the beats. I learn a lot about myself through dance from the rehearsal techniques to how I grasp and apply them. Besides it's also a great way to stay fit, relaxed and healthy.",
    photo: "as a camera truly teaches one a unique way to see the world and I enjoy capturing those moments on my camera to relive the way the world was.",
  }

  return (
    <main>
      {/* Placeholder for Tailwind Generator */}
      <i className="Generator-Tailwind-Classes text-quicken-secondary bg-primary bg-highlight bg-quicken-primary bg-nim-primary text-nim-secondary text-maat-primary bg-maat-primary text-maat-secondary text-highlight text-[#1db500] bg-[#1db500]"></i>
      {/* Introduction Section */}
      <div className="h-auto VStack gap-5 md:gap-8">
        <div className="content ultrawide-container VStack gap-5 md:gap-8 relative ">
          <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/about-me/Mandala.png" alt="" className="absolute w-3/4 md:w-1/3 animate-slow-spin top-[-10%] md:top-[-20%] left-[-25%] md:left-[-15%] mix-blend-color-burn" />
          <div className="VStack items-left font-black">
            <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/images/Banner.jpg" alt="" className="z-10 h-[200px] w-[450px] mb-12 rounded-full object-cover" />
            <div className="font-panel">Hi there, my name is</div>
            <div className="font-panel text-primary uppercase text-[2em] md:text-[5em] font-black">
              Nikesh Kumar
            </div>
          </div>
          <div className="VStack font-bold font-panel justify-between gap-8">
            <div className="description w-full text-left">I am currently in Ann Arbor, studying HCI at the <span className="hover-cursor michigan">University of Michigan</span> while working with Heather on her startup</div>
          </div>
          {/* <div className="VStack md:HStack font-bold font-panel justify-between">
            <div className="description w-full md:w-2/3 text-left">I am currently in Ann Arbor, studying HCI at the <span className="hover-cursor michigan">University of Michigan</span> while working with Heather on her startup</div>
            <div className="divider md:hdivider bg-black my-8 md:mx-4 md:my-0"></div>
            <div className="aspirations w-full md:w-1/3 text-left VStack gap-3">I love exploring different opportunities in life, because there's so much to learn from every different thing out there and use those in different places!
              <br />
            </div>
          </div> */}
          {/* <div className="VStack md:HStack font-sans justify-between"> */}
          <div className="VStack description w-full text-left gap-8">
            <div className="font-black font-panel">EDUCATION</div>
            <div className="long-card edu HStack gap-4">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6T1IZQY0-1Hub6rhGlgDk-iNF0pZHMTfQ8w&s" alt="" />
              <div className="details">
                <div className="font-semibold">University of Michigan</div>
                <div className="font-regular">Masters of Science (M.Sc) in Information Science</div>
                <div className="text-sm font-light">2023 - <i>Present</i></div>
              </div>
            </div>
            <div className="long-card edu HStack gap-4">
              <img src="https://upload.wikimedia.org/wikipedia/en/b/be/Manipal_Tech_new_logo.png" alt="" />
              <div className="details">
                <div className="font-semibold">Manipal Institute of Technology</div>
                <div className="font-regular">Bachelors of Technology (B.Tech) in Information Technology <i>(Minors in Big Data)</i> </div>
                <div className="text-sm font-light">2018 - 2022</div>
              </div>
            </div>
            <div className="font-black">EXPERIENCE</div>
            <div className="long-card edu HStack gap-4">
              <img src="https://trendlyne-media-mumbai-new.s3.amazonaws.com/profilepicture/54898_profilepicture.png" alt="" />
              <div className="details">
                <div className="font-semibold">Tejas Networks</div>
                <div className="font-regular">Software Developer</div>
                <div className="text-sm font-light">2022 - 2023</div>
              </div>
            </div>
            {/* </div> */}
            <div className="aspirations w-full md:w-2/5 text-left">
            </div>
          </div>
        </div>
        {/* <div className="image-carousel mb-8">
          <div className="image-track">
            {[...images, ...images, ...images].map((src, index) => (
              <img key={index} src={src} alt={`Carousel ${index}`} />
            ))}
          </div>
        </div> */}
      </div>

      <div className="content ultrawide-container flex flex-col gap-5 min-h-[calc(100vh-72px)] md:gap-8 bg-primary text-white relative overflow-hidden">
        <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/about-me/Mandala.png" alt="" className="absolute opacity-75 w-full md:w-1/2 animate-slow-spin top-[-10%] md:top-[-20%] right-[-25%] md:right-[-15%] mix-blend-color-dodge" />
        <div className="VStack gap-2.5">
          <div className="HStack justify-start m-auto md:text-xl text-sm">
            <div className="font-panel font-black">
              and I enjoy&nbsp;dancing, photography and<br/> exploring hobbies, culture & people
              {/* <span>
                <select
                  className="pl-2 pr-8 py-2 bg-primary border-4 rounded-xl border-primary appearance-none transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-highlight"
                  name="hobbies"
                  id="hobby"
                  value={selectedHobby}
                  onChange={(e) => setSelectedHobby(e.target.value)}
                > 
                  <option value="design">Designing</option>
                  <option value="dance">Dancing</option>
                  <option value="photo">Photography</option>
                </select>
              </span> */}
            </div>
          </div>
          <div className="VStack md:HStack justify-start m-auto md:text-xl text-sm">
            {/* <div className="font-sans text-3xl font-extralight w-full md:w-2/5">{hobbyDescription[selectedHobby]}</div> */}
            <div className="font-sans text-3xl font-extralight w-full md:w-2/5">I learn a lot about myself through dance from how I grasp and apply them and photography helps me see the world in different way and capture it. But the most interesting aspect is learning the history, people and their cultures. Additionally it's a great way to relax and wind down.</div>
            <div className="w-full md:w-3/5 h-[calc(100vh-240px)] relative">
              {/* {selectedHobby === "dance" && */}
                <>
                  <video autoPlay muted loop playsinline className="rounded-full w-full md:w-2/3 absolute bottom-[130px] md:top-[60px] md:right-0 z-20">
                    <source src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/about-me/dance/dance-um.mp4" type="video/mp4" />
                  </video>
                  <video autoPlay muted loop playsinline className="rounded-full absolute top-[60px] w-1/2 md:w-1/3 md:left-[120px] md:top-0">
                    <source src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/about-me/dance/good-dance.mp4" type="video/mp4" />
                  </video>
                  {/* <img src="https://nikeplusdash-bucket.s3.us-east-2.amazonaws.com/about-me/dance/good-pic.jpg" alt="" className="rounded-full absolute bottom-0 w-1/2 md:w-1/3 md:right-[-40px] md:bottom-[100px] z-10" /> */}
                </>
              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AboutPage;
